<template>
	<div>
		<div class="mb-4" v-for="option in options" :key="`${id}-${option.value}`">
			<v-checkbox
				:value="option.value"
				v-model="selected"
				:hide-details="true"
				:multiple="multiple"
				:label="option.text"
				:disabled="isDisabled"
				class="pb-2"
			>
			</v-checkbox>
			<slot v-if="questionTemplate.answer_options[option.value]"
				name="pollResults"
				v-bind:answer="questionTemplate.answer_options[option.value].value"
			></slot>
		</div>
	</div>
</template>

<style lang="less">
	@import "../../../../src/assets/css/survey/answerOptions.less";
</style>

<script type="text/javascript">
	import _ from "lodash";
	export default {
		name: "Checkboxes",
		props: {
			id: {},
			value: {},
			disabled: {},
			multiple: {type: Boolean, default: false}
		},
		data: () => {
			return {
				selected: []
			};
		},
		computed: {
			questionTemplate() {
				return this.$store.state.questionTemplates.data[this.id];
			},
			options() {
				let options = this.questionTemplate.answer_options;
				return _.sortBy(options, "position");
			},
			isDisabled() {
				return this.disabled || this.question
					? this.question.disable_input
					: false;
			}
		},
		watch: {
			selected(v) {
				if (v) {
					this.$emit("update", v);
				}
			},
			value: {
				immediate: true,
				handler() {
					this.selected = this.value;
				}
			}
		}
	};
	//
</script>
"
